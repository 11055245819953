
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { PersonalMessageDetail } from '../models/personal-message';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  BASEURL: string;

  constructor(public http: HttpClient,public alertController: AlertController,private router: Router) {
    this.BASEURL = environment.api_url;
  }

  async internetError() {
    const alert = await this.alertController.create({
      cssClass: 'internet-issue',
      message: '<p>Der Server konnte nicht erreicht werden. Prüfen Sie, ob Sie mit dem Internet verbunden sind. Sollten Sie dies sichergestellt haben, liegt möglicherweise ein kurzzeitiges Problem vor. Versuchen Sie es dann bitte zu einem späteren Zeitpunkt noch einmal und entschuldigen Sie die Unannehmlichkeiten.</p>',
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.router.navigate(['home']);
          }
        }
      ]
    });
    await alert.present();
  }
  

  getMessages(): Observable<any> {
    let headers = SharedService.getHeader();
    return this.http.get(this.BASEURL + 'message', { headers: headers })
    .pipe(
      map((data: any) => {
        return data;
      }), catchError( error => {
       if(error.status == 466){
        return error;
       }else{
        this.internetError();
        return throwError( 'Internet issue!' );
       }
        
      })
    )
  }

  getUnreadCountMessages(): Observable<any> {
    let headers = SharedService.getHeader();
    return this.http.get(this.BASEURL + 'message-unread', { headers: headers })
    .pipe(
      map((data: any) => {
        return data;
      }), catchError( error => {
       if(error.status == 466){
        return error;
       }else{
        this.internetError();
        return throwError( 'Internet issue!' );
       }
        
      })
    )
  }

  async getMessageDetail(id: number): Promise<PersonalMessageDetail> {
    try {
      let headers = SharedService.getHeader();
      const response = await this.http.get(this.BASEURL + 'message/'+ id, { headers: headers }).toPromise() as PersonalMessageDetail;
      return response;
    } catch (err) {
      return {
        data: {
          id: null,
          title: 'Fehler',
          content: '',
          document_url: '',
          is_feedback: 0,
          feedback_q:[],
          created_date:''
        }
      }
    }
  }

  newMessage(data:any): Observable<any> {
    let headers = SharedService.getHeader();
    return this.http.post(this.BASEURL + 'new-message', data, { headers: headers});
  }

  deleteMessage(data:any): Observable<any> {
    let headers = SharedService.getHeader();
    return this.http.post(this.BASEURL + 'delete-message', data, { headers: headers});
  }

  replyMessage(data:any): Observable<any> {
    let headers = SharedService.getHeader();
    return this.http.post(this.BASEURL + 'message-reply', data, { headers: headers});
  }

  feedback(data:any): Observable<any> {
    let headers = SharedService.getHeader();
    return this.http.post(this.BASEURL + 'feedback', data, { headers: headers});
  }

}
