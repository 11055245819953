import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constructor(private router: Router) {
    }

    public news_id: any = null;
    public msg_id: any = null;
    static LOGIN: string = 'login';
    static TOKEN: string = 'token';
    static ID: string = 'id';
    static USER_ID: string = 'user_id';
    static USER_NAME: string = 'username';
    static EMAIL: string = 'email';
    static PROFILE_PIC: string = 'profile_pic';
    static NAVIGATION: string = 'navigation';
    static ROLE: string = 'role';
    static REMEMBER: string = 'remember';
    static REGIONS: string = 'regions';
    static REGION: string = 'region';
    static NAME: string = 'name';
    static get(key: string) {
        return localStorage.getItem(key);
    }

    static clearStorage() {
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('login');
        localStorage.removeItem('profile_pic');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('regions');
        localStorage.removeItem('name');
        localStorage.removeItem('department_report');
    }

    static set(key: string, value) {
        localStorage.setItem(key, value);
    }

    static getHeader() {
        if (localStorage.getItem('auth_token') != null) {
            return new HttpHeaders()
                .set('Authorization', 'Bearer '+localStorage.getItem('auth_token'))
                .set('Accept', 'application/json')
                // .set('Access-Control-Allow-Headers', '*')
                // .set('Access-Control-Allow-Methods', "GET, POST, DELETE, PUT, PATCH")
                // .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
                // .set('Pragma', 'no-cache')
                // .set('Expires', '0')
                // .set('Access-Control-Allow-Origin', '*');
        }
        return null;
    }


    static getPutHeader() {
        return new HttpHeaders().set(
            'Authorization',
            localStorage.getItem('token')
        ).set('Content-Type', 'application/x-www-form-urlencoded');
    }

    static getPublicHeader() {
        return new HttpHeaders().set('Accept', 'application/json')
        // .set('Access-Control-Allow-Headers', '*')
        // .set('Access-Control-Allow-Methods', "GET, POST, DELETE, PUT, PATCH")
        // .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        // .set('Pragma', 'no-cache')
        // .set('Expires', '0')
        // .set('Access-Control-Allow-Origin', '*');
    }

   
}
