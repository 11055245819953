import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { UnAuthGuardService } from './services/un-auth-guard.service';
import { PersonalMessageResolverService } from './resolver/personal-message-resolver.service';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'customer-login',
    canActivate: [UnAuthGuardService],
    loadChildren: () => import('./auth/customer-login/customer-login.module').then( m => m.CustomerLoginPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'customer-register',
    canActivate: [UnAuthGuardService],
    loadChildren: () => import('./auth/customer-register/customer-register.module').then( m => m.CustomerRegisterPageModule)
  },
  {
    path: 'customer-forgot-password',
    canActivate: [UnAuthGuardService],
    loadChildren: () => import('./auth/customer-forgot-password/customer-forgot-password.module').then( m => m.CustomerForgotPasswordPageModule)
  },
  {
    path: 'order-listing',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./order/order-listing/order-listing.module').then( m => m.OrderListingPageModule)
  },
  {
    path: 'lenses-order-detail',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./order/lenses-order-detail/lenses-order-detail.module').then( m => m.LensesOrderDetailPageModule)
  },
  {
    path: 'glasses-new-order-detail',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./order/glasses-new-order-detail/glasses-new-order-detail.module').then( m => m.GlassesNewOrderDetailPageModule)
  },
  {
    path: 'glasses-repair-order-detail',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./order/glasses-repair-order-detail/glasses-repair-order-detail.module').then( m => m.GlassesRepairOrderDetailPageModule)
  },
  {
    path: 'reorder-request',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./order/reorder-request/reorder-request.module').then( m => m.ReorderRequestPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./auth/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'personal-messages',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./messages/personal-messages/personal-messages.module').then( m => m.PersonalMessagesPageModule)
  },
  {
    path: 'personal-message-detail/:id',
    resolve: {
      detailById: PersonalMessageResolverService
    },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./messages/personal-message-detail/personal-message-detail.module').then( m => m.PersonalMessageDetailPageModule)
  },
  {
    path: 'feedback',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./messages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
